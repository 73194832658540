import axios from 'axios'
import { Commit } from 'vuex'

import { Errors } from '@/types/errors'
import { GlobalState } from '@/types/globalState'

const state: GlobalState = {
  globalErrors: {},
  token: null
}

const getters = {
  getGlobalErrors (state: GlobalState): Errors {
    return state.globalErrors
  },
  getToken (state: GlobalState): string | null {
    return state.token
  }
}

const mutations = {
  setGlobalErrors (state: GlobalState, data: Errors) {
    state.globalErrors = data
  },
  setToken (state: GlobalState, value: string | null): void {
    state.token = value
    if (value) {
      localStorage.setItem('token', value)
    } else {
      localStorage.removeItem('token')
    }
  }
}

const actions = {
  login ({ commit }: { commit: Commit }, data: any): Promise<any> {
    commit('setToken', null)
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_BACKEND_URL}login`, data)
        .then((response: any) => {
          commit('setToken', response.data.token)
          resolve(true)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  },
  logout ({ commit }: { commit: Commit }): void {
    commit('setToken', null)
    localStorage.removeItem('token')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
