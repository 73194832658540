
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
  }
})
export default class App extends Vue {
  get activeIndex (): string {
    let route = 'home'
    if (this.$route && this.$route.name) {
      route = this.$route.name.toString()
    }
    return route
  }

  get isLogged (): boolean {
    if (this.$store.getters['global/getToken'] !== null) {
      return true
    }
    return false
  }

  @Watch('$route', {
    immediate: true
  })
  watchRoute (to: any) {
    const DEFAULT_TITLE = 'ChayAll | Backoffice'
    let title = DEFAULT_TITLE
    if (to.meta && to.meta.title) {
      title += ' | ' + to.meta.title
    }
    document.title = title
  }
}
