export enum InputKind {
  InputHidden = 'InputHidden',
  InputPassword = 'InputPassword',
  InputText = 'InputText',
  InputUrl = 'InputUrl'
}

export interface Field {
  disabled: boolean
  errors: Array<string> | null
  kind: InputKind
  label: string | null
  name: string
  placeholder?: string
  validation?: RegExp
  validationMsg?: string
  allowUpload?: boolean
  allowEmpty?: boolean
  acceptedFileTypes?: string
}

export interface Fieldset {
  legend?: string
  fields: Array<Field>
}

export interface FormDesc {
  cls?: string
  nonFieldErrors: Array<string> | null
  fieldsets: Array<Fieldset>
}

export interface FormModel {
  [key: string]: any
}
