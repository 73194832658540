<template>
  <div>
    <el-alert :title="nonFieldErrors" type="error" v-if="nonFieldErrors"></el-alert>
    <fieldset v-for="(fieldset, index) in formDesc.fieldsets" :class="fieldset.cls"
              v-bind:key="index">
      <legend v-if="fieldset.legend" class="gb-title">{{ fieldset.legend }}</legend>
      <div v-for="(field, index) in fieldset.fields"
           v-bind:key="index" >
        <el-form-item :label="field.label" v-if="field.kind !== inputHidden ">
          <el-input v-if="field.kind === inputText "
            v-model="model[field.name]"
            :disabled="field.disabled" >
          </el-input>
          <el-input type="password" v-if="field.kind === inputPassword "
            v-model="model[field.name]"
            :disabled="field.disabled">
          </el-input>
          <el-input type="url" v-if="field.kind === inputUrl "
            v-model="model[field.name]"
            :disabled="field.disabled">
          </el-input>
        </el-form-item>
        <el-input type="hidden" v-else
          v-model="model[field.name]"></el-input>
        <el-alert :title="fieldErrors(field)" type="error" v-if="fieldErrors(field)"></el-alert>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { InputKind, Field, FormDesc, FormModel } from '@/types/form'

@Options({
  props: {
    formDesc!: Object,
    model!: Object
  }
})
export default class FormGen extends Vue {
  formDesc!: FormDesc
  model!: FormModel

  get inputHidden (): string {
    return InputKind.InputHidden
  }

  get inputPassword (): string {
    return InputKind.InputPassword
  }

  get inputText (): string {
    return InputKind.InputText
  }

  get inputUrl (): string {
    return InputKind.InputUrl
  }

  get nonFieldErrors (): string | null {
    if (this.formDesc.nonFieldErrors && this.formDesc.nonFieldErrors.length) {
      return this.formDesc.nonFieldErrors.join('<br />')
    }
    return null
  }

  fieldErrors (field: Field): string | null {
    if (field.errors) {
      return field.errors.join('<br />')
    }
    return null
  }

  @Watch('model', {
    deep: true
  })
  watchModel (newVal: FormModel) {
    for (const name in newVal) {
      this.formDesc.fieldsets.forEach(fieldset => {
        fieldset.fields.forEach(field => {
          if (field.name === name) {
            field.errors = null
            if (field.validation && field.validationMsg) {
              if (field.validation.test(newVal[name]) === false) {
                field.errors = [field.validationMsg]
              }
            }
          }
        })
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.el-form {
  fieldset {
    border: none;
    width: 100%;
  }

  &.el-form--inline {
    > div {
      display: inline;
      width: auto;
    }

    fieldset {
      display: inline;
      width: auto;

      & > div {
        display: inline;
        width: auto;
      }
    }

    &div.el-form-item {
      display: inline-block;
    }
  }

  .el-alert {
    margin: -22px 0 22px 0;
  }
}

</style>
