import axios from 'axios'
import { Commit } from 'vuex'

import { User, UserState } from '@/types/userState'

const state: UserState = {
  list: null,
  masqueradeToken: null
}

const getters = {
  getMasqueradeToken (state: UserState): string | null {
    return state.masqueradeToken
  },
  getList (state: UserState): Array<User> | null {
    return state.list
  }
}

const mutations = {
  setMasqueradeToken (state: UserState, value: string | null): void {
    state.masqueradeToken = value
  },
  setList (state: UserState, data: Array<User>): void {
    state.list = data
  }
}

const actions = {
  fetchList ({ commit }: { commit: Commit }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let search = ''
      if (data && data.search) {
        search = `?search=${encodeURIComponent(data.search)}`
      }
      axios.get(`${process.env.VUE_APP_BACKEND_URL}users/${search}`)
        .then((response: any) => {
          const list: Array<User> = []
          response.data.results.forEach((res: any) => {
            list.push({
              id: res.id,
              customer: res.customer,
              firstName: res.first_name,
              lastName: res.last_name,
              email: res.email,
              role: res.role,
              dateJoined: res.date_joined
            })
          })
          commit('setList', list)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  },
  masquerade ({ commit }: { commit: Commit }, data: any): Promise<any> {
    commit('setMasqueradeToken', null)
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}users/${data.id}/token`)
        .then((response: any) => {
          commit('setMasqueradeToken', response.data.token)
          resolve(true)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
