<template>
  <div >
    <h1>Back office ChaYall</h1>
    <el-divider></el-divider>
    <el-card class="box-card">
      <el-form ref="form" :model="model" label-width="120px">
        <form-gen :form-desc="formDesc" :model="model" />
        <el-form-item>
          <el-button type="primary" @click="onSubmit">Submit</el-button>
          <el-button>Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

import FormGen from '@/components/FormGen.vue'
import { FormErrors, HttpError } from '../types/errors'
import { InputKind, FormDesc, FormModel } from '../types/form'

@Options({
  components: {
    FormGen
  }
})
export default class Login extends Vue {
  private formDesc: FormDesc = {
    nonFieldErrors: null,
    fieldsets: [{
      fields: [{
        disabled: false,
        errors: null,
        kind: InputKind.InputUrl,
        label: 'email',
        name: 'email',
        validation: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        validationMsg: 'Bad email'
      }, {
        disabled: false,
        errors: null,
        kind: InputKind.InputPassword,
        label: 'password',
        name: 'password'
      }]
    }]
  }

  private model: FormModel = {
    email: '',
    password: ''
  }

  setFormErrors (errors: FormErrors) {
    this.formDesc.nonFieldErrors = null
    // console.log('ERRS', errors)
    for (const name in errors.data) {
      if (name === 'nonFieldErrors') {
        this.formDesc.nonFieldErrors = errors.data[name]
      }
    }
  }

  setErrors (error: HttpError) {
    if (error.status === 500 || error.status === 502 || error.status === 503) {
      this.$router.push('500')
    } else if (error.status === 404) {
      this.$router.push('404')
    } else if (error.status === 401 || error.status === 403) {
      const errors: FormErrors = {
        status: error.status,
        data: {}
      }
      if (error.data.error) {
        errors.data.nonFieldErrors = [error.data.error]
      } else if (error.data.errors) {
        error.data.errors.forEach((error_: string) => {
          const [label, value] = error_.split(':')
          if (!errors.data[label]) {
            errors.data[label] = []
          }
          errors.data[label].push(value)
        })
      }
      this.setFormErrors(errors)
    }
  }

  onSubmit () {
    this.$store.dispatch('global/login', this.model)
      .then(() => {
        let redir: string = '/'
        if (this.$route.query.redirect) {
          redir = this.$route.query.redirect.toString()
        }
        this.$router.push(redir)
      })
      .catch((e: HttpError) => {
        this.setErrors(e)
      })
  }
}
</script>
