<template>
  <div id="app">
    <el-affix v-if="isLogged === true">
      <el-header >
        <el-menu class="el-menu-demo" mode="horizontal" :router="true"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          :default-active="activeIndex"
        >
          <el-menu-item index="home" route="/">Home</el-menu-item>
          <el-menu-item index="customers" route="/customers">Customers</el-menu-item>
          <el-menu-item index="users" route="/users">Users</el-menu-item>
          <el-menu-item index="logout" route="/logout">Logout</el-menu-item>
        </el-menu>
      </el-header>
    </el-affix>
    <el-main>
      <template v-if="isLogged === true">
        <router-view/>
      </template>
      <el-row v-else>
        <el-col :span="12" :offset="6">
          <router-view/>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
  }
})
export default class App extends Vue {
  get activeIndex (): string {
    let route = 'home'
    if (this.$route && this.$route.name) {
      route = this.$route.name.toString()
    }
    return route
  }

  get isLogged (): boolean {
    if (this.$store.getters['global/getToken'] !== null) {
      return true
    }
    return false
  }

  @Watch('$route', {
    immediate: true
  })
  watchRoute (to: any) {
    const DEFAULT_TITLE = 'ChayAll | Backoffice'
    let title = DEFAULT_TITLE
    if (to.meta && to.meta.title) {
      title += ' | ' + to.meta.title
    }
    document.title = title
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
