import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Login from '../views/Login.vue'
import store from '@/store'

function loadView (view: string) {
  return () => import(
    /* webpackChunkName: "view-[request]" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: 0 */
    /* webpackPreload: 0 */
    `@/views/${view}.vue`
  )
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: loadView('Home'),
    alias: '/home',
    meta: {
      title: 'Welcome'
    }
  },
  {
    path: '/404',
    name: '404',
    component: loadView('Error404'),
    meta: {
      title: 'Not found :('
    }
  },
  {
    path: '/500',
    name: '500',
    component: loadView('Error500'),
    meta: {
      title: 'Oups :('
    }
  },
  {
    path: '/customers',
    name: 'customers',
    component: loadView('Fake'),
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'customers-list' },
    children: [
      {
        path: '',
        name: 'customers-list',
        component: loadView('CustomersList'),
        meta: {
          requiresAuth: true,
          title: 'Customers'
        }
      },
      {
        path: ':id',
        name: 'customers-edit',
        component: loadView('CustomersEdit'),
        meta: {
          requiresAuth: true,
          title: 'Customers'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: loadView('Logout')
  },
  {
    path: '/users/',
    name: 'users',
    component: loadView('Fake'),
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'users-list' },
    children: [
      {
        path: '',
        name: 'users-list',
        component: loadView('UsersList'),
        meta: {
          requiresAuth: true,
          title: 'Users'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let token = store.getters['global/getToken']
  if (token === null) {
    token = localStorage.token ? localStorage.token : null
    store.commit('global/setToken', token)
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log('NEED AUTH?', token)
    if (token === null) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
