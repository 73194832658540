import { createStore } from 'vuex'

import customers from './modules/customers'
import global from './modules/global'
import users from './modules/users'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    customers,
    global,
    users
  }
})
