import axios from 'axios'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'

import App from './App.vue'
import router from './router'
import store from './store'

const anonAllowedEndpoints = [
  'login'
]

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (config.url) {
    const endPointName = config.url.substring(
      `${process.env.VUE_APP_BACKEND_URL}`.length)
    if (anonAllowedEndpoints.indexOf(endPointName) === -1) {
      if (config.headers.Authorization === undefined) {
        let token = store.getters['global/getToken']
        if (token === null) {
          token = localStorage.token ? localStorage.token : null
          store.commit('global/setToken', token)
        }
        config.headers.Authorization = `Bearer ${token}`
      }
    }
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
