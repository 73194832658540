
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { InputKind, Field, FormDesc, FormModel } from '@/types/form'

@Options({
  props: {
    formDesc!: Object,
    model!: Object
  }
})
export default class FormGen extends Vue {
  formDesc!: FormDesc
  model!: FormModel

  get inputHidden (): string {
    return InputKind.InputHidden
  }

  get inputPassword (): string {
    return InputKind.InputPassword
  }

  get inputText (): string {
    return InputKind.InputText
  }

  get inputUrl (): string {
    return InputKind.InputUrl
  }

  get nonFieldErrors (): string | null {
    if (this.formDesc.nonFieldErrors && this.formDesc.nonFieldErrors.length) {
      return this.formDesc.nonFieldErrors.join('<br />')
    }
    return null
  }

  fieldErrors (field: Field): string | null {
    if (field.errors) {
      return field.errors.join('<br />')
    }
    return null
  }

  @Watch('model', {
    deep: true
  })
  watchModel (newVal: FormModel) {
    for (const name in newVal) {
      this.formDesc.fieldsets.forEach(fieldset => {
        fieldset.fields.forEach(field => {
          if (field.name === name) {
            field.errors = null
            if (field.validation && field.validationMsg) {
              if (field.validation.test(newVal[name]) === false) {
                field.errors = [field.validationMsg]
              }
            }
          }
        })
      })
    }
  }
}

