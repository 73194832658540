import axios from 'axios'
import { Commit } from 'vuex'

import {
  Customer,
  // CustomerChannel,
  CustomerChannels,
  CustomerSkill,
  CustomerState
} from '@/types/customerState'

const state: CustomerState = {
  list: null,
  obj: null,
  objChannels: null
}

const getters = {
  getList (state: CustomerState): Array<Customer> | null {
    return state.list
  },
  getObj (state: CustomerState): Customer | null {
    return state.obj
  },
  getObjChannels (state: CustomerState): CustomerChannels | null {
    return state.objChannels
  }
}

const mutations = {
  setList (state: CustomerState, data: Array<Customer>): void {
    state.list = data
  },
  setObj (state: CustomerState, data: Customer): void {
    state.obj = data
  },
  setObjChannels (state: CustomerState, data: CustomerChannels): void {
    state.objChannels = data
  },
  setSkillSetupDone (state: CustomerState, value: string): void {
    // const obj = JSON.parse(JSON.stringify(state.obj))
    if (state.obj && state.obj.skills) {
      state.obj.skills.forEach((cSkill: CustomerSkill) => {
        if (cSkill.id === value) {
          cSkill.setupDone = true
        }
      })
    }
    // state.obj = obj
  }
}

const actions = {
  fetchList ({ commit }: { commit: Commit }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let search = ''
      if (data && data.search) {
        search = `?search=${encodeURIComponent(data.search)}`
      }
      axios.get(`${process.env.VUE_APP_BACKEND_URL}customers/${search}`)
        .then((response: any) => {
          const list: Array<Customer> = []
          response.data.results.forEach((res: any) => {
            list.push({
              id: res.id,
              name: res.name,
              offer: res.offer,
              createdOn: res.created_on
            })
          })
          commit('setList', list)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  },
  fetchObj ({ commit }: { commit: Commit }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}customers/${data.id}`)
        .then((response: any) => {
          const skills: Array<CustomerSkill> = []
          response.data.skills.forEach((skill: any) => {
            skills.push({
              id: skill.id,
              label: skill.label,
              setupDone: false
            })
          })
          const customer: Customer = {
            id: response.data.id,
            name: response.data.name,
            offer: response.data.offer,
            skills: skills,
            createdOn: response.data.created_on
          }
          commit('setObj', customer)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  },
  fetchObjChannels ({ commit }: { commit: Commit }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}customers/${data.id}/channels`)
        .then((response: any) => {
          const channels: CustomerChannels = []
          response.data.forEach((customerChannel: any) => {
            channels.push({
              id: customerChannel.id,
              code: customerChannel.code,
              params: customerChannel.params,
              skill: customerChannel.skill,
              status: customerChannel.status
            })
          })
          commit('setObjChannels', channels)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  },
  sendSkill ({ commit }: { commit: Commit }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        skill: data.id,
        provider: 'tyntec',
        phone_number: data.phoneNumber
      }
      axios.post(`${process.env.VUE_APP_BACKEND_URL}customers/whatsapp`, payload)
        .then(() => {
          commit('setSkillSetupDone', data.id)
          resolve(true)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
